import { createContext } from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import io from 'socket.io-client';

import moment, { utc } from 'moment';
// import { getFirebaseToken, onMessageListener } from '../firebaseInit';

const toast = createStandaloneToast();

const getBaseURL = () => {
  // return 'https://kaizengroup-api.siliconvalleyhq.com';

  const host = window.location.host;
  if (host.includes('localhost') || host.includes('web.app')) {
    return 'https://svhq.herokuapp.com';
  }

  const baseURL = host.split('.');
  return `https://${baseURL[0]}-api.${baseURL[1]}.${baseURL[2]}`;
};

export class Main {
  debugMode =
    window.location.host.includes('localhost') ||
    window.location.host.includes('web.app');

  whitelabel = { title: '', logo_path: '' };
  baseURL = getBaseURL();
  userData = null;

  mentorSchedules = [];
  playBooks = [];

  constructor() {
    //check if logged in
    // this.userData = JSON.parse(window.sessionStorage.getItem('userData'));

    const jwt = window.localStorage.getItem('my_jwt');
    if (jwt) {
      this._getMe({ jwt });
    }

    if (this.debugMode) console.log(toJS(this.userData));

    this._getWhiteLabel();

    // getFirebaseToken(found => console.log('getting token', found)).then(res => {
    //   console.log('TOKEN', res);

    //   onMessageListener()
    //     .then(payload => {
    //       console.log('NOTIF!', payload);
    //     })
    //     .catch(err => console.log('failed: ', err));
    // });

    //socket io
    // const socket = io(this.baseURL);
    // socket.on('request_update', data => {});

    // getToken(messaging, {
    //   vapidKey:
    //     'BDg0FGl7jsgSjMop56TimyIbB99l0pPJzsqDg-hESZoCuRldjQ7Bjc_IKmfOybwI3q0E0HBlC8hUDfystSVIEg4',
    // })
    //   .then(currentToken => {
    //     if (currentToken) {
    //       // Send the token to your server and update the UI if necessary
    //       // ...
    //       console.log(currentToken);

    //       onMessage(messaging, payload => {
    //         console.log('Message received. ', payload);
    //         // ...
    //       });
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         'No registration token available. Request permission to generate one.'
    //       );
    //       // ...
    //     }
    //   })
    //   .catch(err => {
    //     console.log('An error occurred while retrieving token. ', err);
    //     // ...
    //   });

    makeAutoObservable(this);
  }

  _getMe = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/user-information`,
        headers: {
          Authorization: `Bearer ${
            this.userData ? this.userData.jwt : params.jwt
          }`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);

          // window.sessionStorage.setItem(
          //   'userData',
          //   JSON.stringify(this.userData)
          // );

          if (params && params.jwt) {
            const data = {
              jwt: params.jwt,
              user: response.data.data,
            };
            this.userData = { ...data };
          } else {
            this.userData.user = response.data.data;
            this.userData = { ...this.userData };
          }

          this._getMySchedules();
          this._getPlaybooks();

          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _globalToast = params => {
    toast(params);
  };

  _register = params => {
    return new Promise(resolve => {
      // axios.get(`${this.baseURL}/sanctum/csrf-cookie`).then(response => {
      axios
        .post(
          `${this.baseURL}/api/register`,
          {
            first_name: params.firstName,
            last_name: params.lastName,
            email: params.username,
            password: params.password,
          },
          {
            xsrfHeaderName: 'X-XSRF-TOKEN',
            withCredentials: true,
          }
        )
        .then(response => {
          if (this.debugMode) console.log(response);
          if (response.data.token) {
            this._globalToast({
              title: 'Registration successful',
              status: 'success',
              description: response.data.message,
            });
            resolve(true);
          } else {
            this._globalToast({
              title: 'Error',
              status: 'error',
              description: response.data.message,
            });
            resolve(false);
          }
        })
        .catch(err => {
          resolve(false);
          if (this.debugMode) console.log('err', err.response);
          this._globalToast({
            title: 'Error',
            status: 'error',
            description: Object.keys(err.response.data.errors).map(
              key => err.response.data.errors[key]
            ),
          });
        });
    });
    // });
  };

  _login = params => {
    //uncomment to connect to strapi
    // this.userData = { user: { username: params.username } };
    // window.sessionStorage.setItem('userData', JSON.stringify(this.userData));
    // return Promise.resolve();

    return new Promise(resolve => {
      // axios.get(`${this.baseURL}/sanctum/csrf-cookie`).then(response => {
      axios
        .post(
          `${this.baseURL}/api/login`,
          {
            email: params.username,
            password: params.password,
          },
          {
            xsrfHeaderName: 'X-XSRF-TOKEN',
            withCredentials: true,
          }
        )
        .then(response => {
          if (response.data.token) {
            const data = {
              jwt: response.data.token,
              user: response.data.user_info,
            };

            if (this.debugMode) console.log('Login', response);

            // window.sessionStorage.setItem('userData', JSON.stringify(data));
            window.localStorage.setItem('my_jwt', response.data.token);

            this.userData = data;
            this._globalToast({
              title: 'Login successful',
              status: 'success',
              description: response.data.message,
            });

            this._getMySchedules();
            this._getPlaybooks();

            resolve(true);
          } else {
            this._globalToast({
              title: 'Error',
              status: 'error',
              description: response.data.message,
            });
            resolve(false);
          }
        })
        .catch(err => {
          if (this.debugMode) console.log('ERROR', err.response);
          this._globalToast({
            title: 'Error',
            status: 'error',
            description: err && String(err.response),
          });
        });
    });
    // });
  };

  _logout = cb => {
    this.userData = null;
    this.mentorSchedules = [];

    window.localStorage.removeItem('my_jwt');

    this._globalToast({
      title: 'You have been logged out.',
      status: 'success',
    });

    return Promise.resolve();
  };

  _getMySchedules = params => {
    if (!this.userData) return;
    if (this.userData && this.userData.user.user_type !== 'mentor') return;

    return axios({
      method: 'get',
      url: `${this.baseURL}/api/mentor/my-schedule`,
      headers: {
        Authorization: `Bearer ${this.userData.jwt}`,
      },
    })
      .then(response => {
        if (this.debugMode) console.log('RES', response);
        this.mentorSchedules = response.data.data;
      })
      .catch(err => {
        if (this.debugMode) console.log(err.response);
      });
  };

  _setSchedule = ({ to, from, days }) => {
    var utcFrom = new moment(from, 'HH:mm').utc();
    var utcTo = new moment(to, 'HH:mm').utc();

    if (this.debugMode) console.log(utcTo.format('HH:mm'));
    if (this.debugMode) console.log(utcFrom.format('HH:mm'));
    if (this.debugMode) console.log(days.length);

    if (utcFrom.format('HH:mm') === 'Invalid date') {
      this._globalToast({
        title: 'Invalid From date',
        status: 'error',
        description: 'Please set a valid date',
      });

      return;
    }
    if (utcTo.format('HH:mm') === 'Invalid date') {
      this._globalToast({
        title: 'Invalid To date',
        status: 'error',
        description: 'Please set a valid date',
      });
      return;
    }
    if (days.length <= 0) {
      this._globalToast({
        title: 'No days selected',
        status: 'error',
        description: 'Please select one or more days',
      });
      return;
    }

    const payload = {
      mentor_id: this.userData.user.id,
      start_time: utcFrom.format('HH:mm'),
      end_time: utcTo.format('HH:mm'),
      days: days,
    };

    if (this.debugMode) console.log('PAYLOAD', payload);

    return axios({
      method: 'post',
      url: `${this.baseURL}/api/mentor-availability/set-schedule`,
      headers: {
        Authorization: `Bearer ${this.userData.jwt}`,
      },
      data: payload,
    })
      .then(response => {
        if (this.debugMode) console.log('RES', response);

        this._getMySchedules();

        this._globalToast({
          title: 'Scheduled saved',
          status: 'success',
        });
      })
      .catch(err => {
        if (this.debugMode) console.log(err.response);
      });
  };

  _deleteSchedule = params => {
    return new Promise(resolve =>
      axios({
        method: 'delete',
        url: `${this.baseURL}/api/mentor-availability/set-schedule/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);

          this._getMySchedules();

          this._globalToast({
            title: 'Schedule Deleted!',
            status: 'error',
          });
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getMentors = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/mentors`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
        })
    );
  };

  _getMentorSchedule = ({ date, duration, mentor_id }) => {
    const formatDate = moment(date);

    const payload = {
      date: formatDate.format('YYYY-MM-DD'),
      duration: duration,
      mentor_id: mentor_id,
    };

    if (this.debugMode) console.log('Payload', payload);

    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/mentor/schedules`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        params: payload,
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data.data[0]);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
        })
    );
  };

  _bookRequest = ({
    mentor_id,
    schedule_id,
    date_schedule,
    duration,
    start_time,
    end_time,
    title,
    topics,
    targets,
    files,
  }) => {
    const formData = new FormData();
    formData.append('mentor_id', mentor_id);
    formData.append('schedule_id', schedule_id);
    formData.append('date_schedule', date_schedule);
    formData.append('duration', duration);
    formData.append('start_time', start_time);
    formData.append('end_time', end_time);
    formData.append('title', title);
    formData.append('topics', topics);
    // formData.append('targets', JSON.stringify(targets));

    targets.map(t => formData.append('targets[]', JSON.stringify(t)));

    files.map(file => {
      formData.append('attachments[]', file);
      formData.append('attachment_data[]', file.name);
    });

    // const payload = {
    //   mentor_id,
    //   schedule_id,
    //   date_schedule,
    //   duration,
    //   start_time,
    //   end_time,
    //   title,
    //   topics,
    //   targets,
    // };
    if (this.debugMode) console.log('Payload', formData);

    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/mentee/booking/appointment`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: formData,
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);

          if (response.data.status === 409) {
            this._globalToast({
              title: 'Schedule Conflict!',
              status: 'error',
              description: `Conflicting with: ${JSON.stringify(
                response.data.data
              )}`,
            });
            resolve(false);
          } else {
            this._globalToast({
              title: 'Mentoring request submitted!',
              status: 'success',
            });
            resolve(true);
          }
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getMenteeBookings = ({ status }) => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/mentee/appointments/${status}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES!?', response);
          resolve(response.data.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
        })
    );
  };

  _getMentorBookings = ({ status }) => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/mentor/appointments/${status}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
        })
    );
  };

  _updateBooking = ({ status, id }) => {
    return new Promise(resolve =>
      axios({
        method: 'put',
        url: `${this.baseURL}/api/mentor/appointments/${id}/status`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          status,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(true);

          if (response.data.status === 200) {
            this._globalToast({
              title: 'Booking Updated',
              status: 'success',
              description: response.data.data,
            });
          }
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getWhiteLabel = () => {
    axios.get(`${this.baseURL}/api/white-label`).then(response => {
      if (this.debugMode) console.log(response);
      this.whitelabel = response.data.data;
    });
  };

  //ADMIN STUFF
  _getFeaturedMentors = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/users/featured-mentors`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getFeaturedMentees = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/users/featured-mentees`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getMembersSearch = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/users-list?search=${
          params.search ? params.search : ''
        }`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);

          if (response.data.message === 'Unauthenticated.') {
            resolve(false);
          } else {
            resolve(response.data);
          }
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _updateFeaturedMember = params => {
    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/user/${
          params.feature ? '' : 'remove-'
        }feature/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          priority: params.priority,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
            description:
              err.response.data.errors &&
              Object.keys(err.response.data.errors).map(
                key => err.response.data.errors[key]
              ),
          });
        })
    );
  };

  _getInsights = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/insight`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);

          if (response.data.message === 'Unauthenticated.') {
            resolve(false);
          } else {
            resolve(response.data);
          }
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _updateInsight = params => {
    const formData = new FormData();

    if (params.thumbnail) formData.append('thumbnail', params.thumbnail);
    formData.append('title', params.title);
    formData.append('url', params.url);
    formData.append('priority', params.priority);
    formData.append('feature', params.feature);
    if (params.user_id) formData.append('user_id', params.user_id);
    formData.append('category_name', params.category_name);

    return new Promise(resolve =>
      axios({
        method: params.delete ? 'delete' : 'post',
        url: `${this.baseURL}/api/insight${params.id ? '/' + params.id : ''}${
          params.edit ? '?_method=PUT' : ''
        }`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: formData,
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);

          if (response.status === 200) {
            resolve(true);

            this._globalToast({
              title: 'Insight Updated',
              status: 'success',
            });
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
            description:
              err.response.data.errors &&
              Object.keys(err.response.data.errors).map(
                key => err.response.data.errors[key]
              ),
          });
        })
    );
  };

  _getCareers = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/career`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _updateCareer = params => {
    const formData = new FormData();

    if (params.thumbnail) formData.append('thumbnail', params.thumbnail);
    formData.append('title', params.title);
    formData.append('company_type', params.company_type);
    formData.append('position', params.position);
    formData.append('url', params.url);
    formData.append('priority', params.priority);
    formData.append('feature', params.feature);

    return new Promise(resolve =>
      axios({
        method: params.delete ? 'delete' : 'post',
        url: `${this.baseURL}/api/career${params.id ? '/' + params.id : ''}${
          params.edit ? '?_method=PUT' : ''
        }`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: formData,
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);

          if (response.status === 200) {
            resolve(true);

            this._globalToast({
              title: 'Insight Updated',
              status: 'success',
            });
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
            description:
              err.response.data.errors &&
              Object.keys(err.response.data.errors).map(
                key => err.response.data.errors[key]
              ),
          });
        })
    );
  };

  _getPitches = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/pitches`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _updatePitch = params => {
    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/pitch/${
          params.feature ? '' : 'remove-'
        }feature/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          priority: params.priority,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
            description:
              err.response.data.errors &&
              Object.keys(err.response.data.errors).map(
                key => err.response.data.errors[key]
              ),
          });
        })
    );
  };

  _getMember = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/user/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _updateProfile = params => {
    var data = new FormData();
    data.append('first_name', params.first_name);
    data.append('last_name', params.last_name);
    if (params.about_me) data.append('about_me', params.about_me);
    if (params.address_line_1)
      data.append('address_line_1', params.address_line_1);
    if (params.city) data.append('city', params.city);
    if (params.postal_code) data.append('postal_code', params.postal_code);
    if (params.state) data.append('state', params.state);
    if (params.country) data.append('country', params.country);
    if (params.phone_number) data.append('phone_number', params.phone_number);
    if (params.company) data.append('company', params.company);

    params.super_powers.map(sp => {
      data.append('super_powers[]', sp.name);
    });
    params.skills.map(skill => {
      data.append('skills[]', skill.name);
    });
    params.industries.map(industry => {
      data.append('industries[]', industry.name);
    });

    if (params.profile_picture)
      data.append('profile_picture', params.profile_picture);

    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/update-profile?_method=PUT`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: data,
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(true);

          this._getMe();

          this._globalToast({
            title: 'Profile Updated!',
            status: 'success',
          });
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
            description:
              err.response.data.errors &&
              Object.keys(err.response.data.errors).map(
                key => err.response.data.errors[key]
              ),
          });
        })
    );
  };
  _getFeedbackDetails = () => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/feedbacks/user/my-feedbacks`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log(`RES`, response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getAppointmentDetails = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/${params.user_type}/appointment/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _deleteAppointment = params => {
    return new Promise(resolve =>
      axios({
        method: 'delete',
        url: `${this.baseURL}/api/mentee/booking/appointment/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);

          this._globalToast({
            title: 'Request deleted!',
            status: 'error',
          });
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _addComment = params => {
    const payload = {
      appointment_id: params.id,
      comment: params.comment,
    };
    if (this.debugMode) console.log(payload);
    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/appointments/comment`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: payload,
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);

          this._globalToast({
            title: 'Comment added!',
            status: 'success',
          });
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _meeting = params => {
    if (this.debugMode) console.log('MEETING', JSON.stringify(params));
    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/${params.action}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          // meeting_id: params.meeting_id,
          // password: params.password,
          appointment_id: params.appointment_id,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _webinar = params => {
    if (this.debugMode) console.log('MEETING', JSON.stringify(params));
    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/webinar/${params.action}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          webinar_id: params.webinar_id,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getPlaybooks = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/playbook-list`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);

          this.playBooks = response.data.data;
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _createWebinar = params => {
    const payload = {
      mentor_id: params.mentor_id,
      industry_id: params.industry_id,
      date: moment(params.date).format('YYYY-MM-DD'),
      title: params.title,
      time_from: moment(params.time_from, 'HH:mm').utc().format('HH:mm'),
      time_to: moment(params.time_to, 'HH:mm').utc().format('HH:mm'),
      seats: params.seats,
      short_desc: params.short_desc,
      description: params.description,
    };
    if (this.debugMode) console.log(payload);

    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/webinars`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: payload,
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);

          this._globalToast({
            title: 'Webinar Created!',
            status: 'success',
          });
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
          });
        })
    );
  };

  _getWebinars = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/webinars`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getWebinar = params => {
    return new Promise(resolve =>
      axios({
        method: params.edit ? 'put' : 'get',
        url: `${this.baseURL}/api/webinars/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          title: params.title,
          industry_id: params.industry_id,
          seats: params.seats,
          short_desc: params.short_desc,
          description: params.description,
          is_visible: params.is_visible,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getIncomingEvents = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/incoming-events`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getIncomingEvent = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/incoming-events/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _joinWebinar = params => {
    return new Promise(resolve =>
      axios({
        method: 'post',
        url: `${this.baseURL}/api/webinars/join`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          webinar_id: params.webinar_id,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);

          this._globalToast({
            title: 'Joining Webinar...',
            status: 'warning',
            description: response.data.message ? response.data.message : '',
          });
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
          });
        })
    );
  };

  _getMyWebinars = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/my-webinars`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _getWebinarRequests = params => {
    return new Promise(resolve =>
      axios({
        method: 'get',
        url: `${this.baseURL}/api/mentor/webinars/${params.status}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);
        })
    );
  };

  _updateWebinarStatus = params => {
    return new Promise(resolve =>
      axios({
        method: 'put',
        url: `${this.baseURL}/api/webinars/status/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: {
          status: params.status,
        },
      })
        .then(response => {
          if (this.debugMode) console.log('RES', response);
          resolve(response.data);

          this._globalToast({
            title: 'Status updated',
            status: 'success',
            // description: response.data.message,
          });
        })
        .catch(err => {
          if (this.debugMode) console.log(err.response);
          resolve(false);

          this._globalToast({
            title: 'Error',
            status: 'error',
            // description: response.data.message,
          });
        })
    );
  };
}

export default createContext(new Main());
