import { React, useContext, useState, useEffect } from 'react';
import {
  Box,
  Text,
  Divider,
  Stack,
  Circle,
  Button,
  Image,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import Panel from '../shared/Panel';
import MainStore from '../stores/MainStore';
import { useHistory } from 'react-router-dom';

function Feedbacks() {
  const store = useContext(MainStore);
  const history = useHistory();
  const [data, setData] = useState([]);

  const getFeedbacks = () => {
    store._getFeedbackDetails().then(res => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getFeedbacks();
    const interval = setInterval(() => {
      getFeedbacks();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Panel flex="1" w="100%">
        <Box>
          <Box p="12px">
            <Text
              fontSize="18px"
              fontFamily="Source Sans Pro"
              fontWeight="600"
              color="#444444"
            >
              Feedback
            </Text>
            <Text fontSize="12px" fontFamily="Poppins" color="#999999">
              Latest
            </Text>
          </Box>
          <Divider />
          <Stack spacing={0} maxHeight={'150px'} overflowX="auto">
            {data.map(i => {
              return (
                <>
                  <Flex flex="1" alignItems="center" p="10px">
                    {i.feedback_created_by ? (
                      <Image
                        mr="10px"
                        backgroundColor="grey"
                        borderRadius="full"
                        boxSize="30px"
                        src={i.feedback_created_by.profile_picture}
                      />
                    ) : (
                      <Circle mr="10px" size="30px" bg="grey" color="black">
                        <Icon as={FaUser} boxSize="18px" circle />
                      </Circle>
                    )}

                    <Box flex="1">
                      <Text
                        fontSize="16px"
                        fontFamily="Roboto"
                        fontWeight="100"
                        color="#0e1111"
                      >
                        {i.feedback_created_by.full_name}
                      </Text>
                      <Text fontSize="16px" fontFamily="Roboto" color="#999999">
                        {i.feedback}
                      </Text>
                    </Box>
                  </Flex>
                  <Divider />
                </>
              );
            })}
          </Stack>
          <Box p="12px">
            <Button
              _focus={{ boxShadow: 'none' }}
              variant="link"
              isFullWidth
              onClick={() => history.push('/user/feedbacks')}
            >
              View All Feedbacks
            </Button>
          </Box>
        </Box>
      </Panel>
    </div>
  );
}

export default Feedbacks;
