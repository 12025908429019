/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, createContext, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Image,
  Circle,
  Square,
  Wrap,
  WrapItem,
  Switch as CSwitch,
} from '@chakra-ui/react';

import { FaUser, FaEdit, FaImage } from 'react-icons/fa';

import { AiFillFileAdd } from 'react-icons/ai';

import {
  BrowserRouter as Router,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Logo from '../assets/svhq_logo.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import VideoPlayer from '../shared/VideoPlayer';

const MentorSpotlights = ({ title, subtitle }) => {
  const store = useContext(MainStore);
  let location = useLocation();
  let { path } = useRouteMatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);

  const [selected, setSelected] = useState();
  const [label, setlabel] = useState('');
  const [url, seturl] = useState('');
  const [order, setorder] = useState(null);
  const [userid, setuserid] = useState(null);
  const [cat, setcat] = useState(null);
  const [featured, setfeatured] = useState(false);
  const [image, setimage] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setloading] = useState(false);

  const [show, setShow] = useState(false);

  const onFilesChange = files => {
    store._globalToast({
      title: 'File loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    setimage(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onFilesError = (error, file) => {
    if (store.debugMode)
      console.log('error code ' + error.code + ': ' + error.message);
  };

  const clearFields = () => {
    setlabel('');
    seturl('');
    setorder('');
    setcat('');
    setuserid('');
    setfeatured(false);
    setimage('');
    setSelected(null);
    setimage('');
    setFile(null);
  };

  const updateData = () => {
    store._getInsights().then(res => {
      if (res) {
        if (store.userData.user.user_type === 'admin') {
          setData(res.data.sort((a, b) => a.priority - b.priority));
        } else {
          const featured = res.data
            .filter(d => d.feature)
            .sort((a, b) => a.priority - b.priority);
          setData(featured);
        }
      }
    });
  };

  useEffect(() => {
    if (location.pathname === path) {
      updateData();
    }
    return () => {};
  }, [location]);

  useEffect(() => {
    store._getInsights().then(res => {
      if (res) {
        if (store.userData.user.user_type === 'admin') {
          setData(res.data.sort((x, y) => x.priority - y.priority));
        } else {
          const featured = res.data
            .filter(d => d.feature)
            .sort((a, b) => a.priority - b.priority);
          setData(featured);
        }
      }
    });
    return () => {};
  }, []);

  return (
    <Box px="36px" py="12px">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selected ? 'Edit' : 'Add New'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="10px">
              <Files
                className="files-dropzone"
                onChange={files => onFilesChange(files)}
                onError={onFilesError}
                accepts={['image/*']}
                multiple={false}
                // maxFileSize={10000000}
                // minFileSize={0}
                clickable
              >
                {image ? (
                  <Image
                    m="0 auto"
                    cursor="pointer"
                    boxSize="200px"
                    objectFit="cover"
                    src={image ? image : ''}
                    alt="Thumbnail"
                    ignoreFallback
                  />
                ) : (
                  <Square
                    m="0 auto"
                    boxSize="200px"
                    border="1px solid gray"
                    borderRadius={4}
                    backgroundColor="white"
                    cursor="pointer"
                    _hover={{ opacity: 0.8 }}
                  >
                    <Icon
                      as={FaImage}
                      w={'40px'}
                      h={'40px'}
                      // mb={5}
                    />
                  </Square>
                )}
              </Files>
              <Input
                value={label}
                onChange={e => setlabel(e.target.value)}
                type="text"
                placeholder="Title"
              />
              <Input
                value={url}
                onChange={e => seturl(e.target.value)}
                type="text"
                placeholder="URL"
              />
              <Input
                value={order}
                onChange={e => setorder(e.target.value)}
                type="number"
                placeholder="Order"
              />
              <Input
                value={cat}
                onChange={e => setcat(e.target.value)}
                type="text"
                placeholder="Category Name"
              />
              <Input
                display="none"
                value={userid}
                onChange={e => setuserid(e.target.value)}
                type="number"
                placeholder="User ID"
              />
              <HStack>
                <CSwitch
                  size="md"
                  isChecked={featured}
                  onChange={e => setfeatured(e.target.checked)}
                />
                <Text>Featured</Text>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            {selected ? (
              <>
                <Button
                  isLoading={loading}
                  colorScheme="red"
                  mr={3}
                  onClick={() => {
                    setloading(true);
                    store
                      ._updateInsight({
                        id: selected.id,
                        delete: true,
                      })
                      .then(res => {
                        setloading(false);
                        if (res) {
                          updateData();
                          clearFields();
                          onClose();
                        }
                      });
                  }}
                >
                  Delete
                </Button>
                <Button
                  isLoading={loading}
                  colorScheme="blue"
                  onClick={() => {
                    setloading(true);
                    store
                      ._updateInsight({
                        title: label,
                        url,
                        priority: order,
                        feature: featured,
                        thumbnail: file,
                        id: selected.id,
                        edit: true,
                        user_id: userid,
                        category_name: cat,
                      })
                      .then(res => {
                        setloading(false);
                        if (res) {
                          updateData();
                          clearFields();
                          onClose();
                        }
                      });
                  }}
                >
                  Update
                </Button>
              </>
            ) : (
              <Button
                isLoading={loading}
                loadingText="Saving..."
                colorScheme="blue"
                onClick={() => {
                  setloading(true);
                  store
                    ._updateInsight({
                      title: label,
                      url,
                      priority: order,
                      feature: featured,
                      thumbnail: file,
                      category_name: cat,
                    })
                    .then(res => {
                      setloading(false);
                      if (res) {
                        updateData();
                        clearFields();
                        onClose();
                      }
                    });
                }}
              >
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VideoPlayer
        isOpen={show}
        data={selected}
        onClose={() => setShow(false)}
      />

      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'initial', lg: 'center' }}
        justifyContent="flex-start"
      >
        <HStack>
          {store.userData && store.userData.user.user_type === 'admin' && (
            <IconButton
              icon={<AiFillFileAdd />}
              colorScheme="blue"
              onClick={() => {
                onOpen();
                setlabel('');
                seturl('');
                setorder('');
                setfeatured(false);
                setimage('');
                setSelected(null);
              }}
            />
          )}
          <Text fontSize="24px" fontWeight="bold">
            {title}
          </Text>
        </HStack>
        <Text ml={{ base: '0px', lg: '10px' }} fontSize="16px" color="grey">
          {subtitle}
        </Text>
      </Flex>

      {data.filter(f => f.category && f.category.includes('spotlight')).length >
        0 && (
        <Box mt="10px">
          <Text fontSize="24px" fontWeight="bold">
            Mentor Spotlights
          </Text>
        </Box>
      )}
      <Wrap mt="20px" spacing="40px">
        {data
          .filter(f => f.category && f.category.includes('spotlight'))
          .map((x, y) => {
            return (
              <WrapItem key={`w${y}`}>
                <Box
                  w="240px"
                  h="240px"
                  onClick={() => {
                    setSelected(x);
                    if (x.category.includes('video')) {
                      setShow(true);
                    } else {
                      window.open(x.url);
                    }
                  }}
                  cursor="pointer"
                  pos="relative"
                >
                  {x.thumbnail ? (
                    <Box
                      h="60%"
                      backgroundColor="black"
                      backgroundImage={x.thumbnail}
                      backgroundSize="cover"
                      backgroundRepeat="no-repeat"
                      backgroundPosition="center center"
                      opacity={x.feature ? 1 : 0.5}
                    ></Box>
                  ) : (
                    <Box
                      h="60%"
                      backgroundColor="black"
                      opacity={x.feature ? 1 : 0.5}
                    >
                      <Center h="100%" p="20px">
                        {/* <Text color="white">Image Unavailable</Text> */}
                        <Image src={Logo} />
                      </Center>
                    </Box>
                  )}

                  {store.userData && store.userData.user.user_type === 'admin' && (
                    <IconButton
                      pos="absolute"
                      top="4px"
                      right="4px"
                      icon={<FaEdit />}
                      colorScheme="blue"
                      zIndex="99"
                      onClick={e => {
                        e.stopPropagation();
                        // setlabel(data[i].title);
                        // seturl(data[i].url);
                        // setorder(data[i].priority);
                        // setcat(data[i].category);
                        // setuserid(data[i].author && data[i].author.id);
                        // setfeatured(data[i].feature);
                        // setimage(data[i].thumbnail);
                        // setSelected(data[i]);
                        setlabel(x.title);
                        seturl(x.url);
                        setorder(x.priority);
                        setcat(x.category);
                        setuserid(x.author && x.author.id);
                        setfeatured(x.feature);
                        setimage(x.thumbnail);
                        setSelected(x);
                        onOpen();
                      }}
                    />
                  )}

                  <Box
                    pos="relative"
                    h="40%"
                    // borderLeft="5px solid #FBC02C"
                    p="6px"
                    opacity={x.feature ? 1 : 0.5}
                  >
                    <Text lineHeight="18px" fontWeight="bold">
                      {x.title}
                    </Text>
                    <Box pos="absolute" right="8px" bottom="6px" display="none">
                      <Flex alignItems="center">
                        <Box textAlign="right" mr="6px">
                          <Text fontSize="14px" fontWeight="bold">
                            {x.author && x.author.full_name
                              ? x.author.full_name
                              : ''}
                          </Text>
                          <Text fontSize="12px">
                            {x.author && x.author.company
                              ? x.author.company
                              : ''}
                          </Text>
                        </Box>

                        {x.author && x.author.profile_picture ? (
                          <Image
                            mr="10px"
                            backgroundColor="grey"
                            borderRadius="full"
                            boxSize="30px"
                            src={x.author.profile_picture}
                            alt={store.userData.user.full_name}
                          />
                        ) : (
                          <Circle size="30px" bg="grey" color="black">
                            <Icon as={FaUser} boxSize="18px" circle />
                          </Circle>
                        )}
                      </Flex>
                    </Box>
                  </Box>
                  {store.userData &&
                    store.userData.user.user_type === 'admin' && (
                      <Text>{`Order: ${x.priority ?? 'Not Set'}`}</Text>
                    )}
                </Box>
              </WrapItem>
            );
          })}
      </Wrap>
    </Box>
  );
};

export default MentorSpotlights;
