/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react';

import ReactPlayer from 'react-player';

const VideoPlayer = ({ isOpen, onClose, data }) => {
  const [ready, setready] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setready(false);
    }
    return () => {};
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data && data.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box textAlign="center" display={!ready ? 'block' : 'none'}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
          <Box display={ready ? 'block' : 'none'}>
            {data && (
              <ReactPlayer
                onReady={() => setready(true)}
                playing={ready}
                controls={true}
                // width="100%"
                // height="100%"
                width="auto"
                height="480px"
                url={data.url}
              />
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VideoPlayer;
