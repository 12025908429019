/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Button,
  Icon,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Circle,
  Switch as CSwitch,
  Divider,
  Link as Anchor,
} from '@chakra-ui/react';

import { FaUser } from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import moment from 'moment';

const RequestInfoModal = ({
  appointment,
  feedback,
  isOpen,
  onClose,
  onComplete,
  title,
  type,
}) => {
  const store = useContext(MainStore);
  const [data, setData] = useState();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const divRef = useRef(null);

  const updateData = () => {
    store
      ._getAppointmentDetails({
        user_type: type,
        id: appointment.appointment_id,
      })
      .then(res => {
        if (store.debugMode) console.log('APP!', res);
        if (res) setData(res.data);
      });
    store
      ._getFeedbackDetails({
        id: feedback.data.id,
      })
      .then(res => {
        if (store.debugMode) console.log('APP!', res);
        if (res) setData(res.data);
      });
    console.log('wew');
  };

  const getTime = (d, t) => {
    let time = moment.utc(t, 'HH:mm::ss').local().format('HH:mm:ss');
    let date = moment(`${d} ${time}`).fromNow();
    return date;
  };

  useEffect(() => {
    if (isOpen) {
      updateData();
    }
    if (divRef && divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
    return () => {};
  }, [isOpen, index]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs onChange={i => setIndex(i)}>
            <TabList>
              <Tab>Details</Tab>
              <Tab>Comments</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {data && (
                  <Stack>
                    {data && type === 'mentee' ? (
                      <>
                        <VStack>
                          {appointment.mentor.profile_picture ? (
                            <Image
                              cursor="pointer"
                              boxSize="60px"
                              objectFit="cover"
                              src={
                                appointment.mentor.profile_picture
                                  ? appointment.mentor.profile_picture
                                  : ''
                              }
                              alt="Thumbnail"
                              ignoreFallback
                              borderRadius="full"
                            />
                          ) : (
                            <Circle
                              boxSize="60px"
                              border="1px solid gray"
                              backgroundColor="white"
                              cursor="pointer"
                              _hover={{ opacity: 0.8 }}
                            >
                              <Icon
                                as={FaUser}
                                w={'40px'}
                                h={'40px'}
                                opacity="0.5"
                              />
                            </Circle>
                          )}
                        </VStack>
                        <Text>{`Name: ${data.mentor.full_name}`}</Text>
                      </>
                    ) : (
                      <>
                        <VStack>
                          {appointment.booked_user.profile_picture ? (
                            <Image
                              cursor="pointer"
                              boxSize="60px"
                              objectFit="cover"
                              src={
                                appointment.booked_user.profile_picture
                                  ? appointment.booked_user.profile_picture
                                  : ''
                              }
                              alt="Thumbnail"
                              ignoreFallback
                              borderRadius="full"
                            />
                          ) : (
                            <Circle
                              boxSize="60px"
                              border="1px solid gray"
                              backgroundColor="white"
                              cursor="pointer"
                              _hover={{ opacity: 0.8 }}
                            >
                              <Icon
                                as={FaUser}
                                w={'40px'}
                                h={'40px'}
                                opacity="0.5"
                              />
                            </Circle>
                          )}
                        </VStack>
                        <Text>{`Name: ${appointment.booked_user.full_name}`}</Text>
                      </>
                    )}

                    <Text>{`Schedule: ${moment(data.date_schedule).format(
                      'LL'
                    )} at ${moment
                      .utc(data.start_time, 'HH:mm::ss')
                      .local()

                      .format('h:mm a')} - ${moment
                      .utc(data.end_time, 'HH:mm::ss')
                      .local()

                      .format('h:mm a')} `}</Text>
                    <Text>{`Duration: ${data.duration} Min`}</Text>
                    <Divider />
                    <Text>{`Startup: ${data.title}`}</Text>
                    <Text>{`Topic: ${data.topics}`}</Text>
                    <Divider />
                    <Text>Targets:</Text>
                    {JSON.parse(data.targets).map((t, i) => {
                      const target = JSON.parse(t);
                      return <Text>{`${target['target_' + (i + 1)]}`}</Text>;
                    })}
                    <Divider />
                    {data.attachments.length > 0 && (
                      <Text fontSize="14px" fontWeight="bold">
                        Attachments:
                      </Text>
                    )}
                    {data.attachments.map(a => {
                      return (
                        <Anchor href={a.url} isExternal>
                          {a.name}
                        </Anchor>
                      );
                    })}
                    {data.status_name === 'ACCEPTED' && (
                      <>
                        {/* <Text textAlign="center" fontWeight="bold">
                            {`Mentoring will start in ${getTime(
                              data.date_schedule,
                              data.start_time
                            )}`}
                          </Text> */}
                        <Text textAlign="center" fontWeight="bold">
                          {`Schedule: ${getTime(
                            data.date_schedule,
                            data.start_time
                          )}`}
                        </Text>
                        <Button
                          mt="10px !important"
                          p="10px"
                          colorScheme="green"
                          onClick={() => {
                            store
                              ._meeting({
                                action: type === 'mentee' ? 'join' : 'start',
                                appointment_id: data.appointment_id,
                              })
                              .then(res => {
                                if (store.debugMode) console.log('BBB', res);

                                if (res) {
                                  if (res.url === 'not started') {
                                    store._globalToast({
                                      title:
                                        'Mentor has not started the meeting',
                                      status: 'error',
                                    });
                                  } else {
                                    store
                                      ._addComment({
                                        id: appointment.appointment_id,
                                        comment: `${store.userData.user.full_name} has joined the meeting`,
                                      })
                                      .then(res => {
                                        if (res) {
                                          updateData();
                                        }
                                      });
                                    window.open(res.url);
                                  }
                                }
                              });
                          }}
                        >
                          Click to Start Meeting
                        </Button>
                      </>
                    )}
                  </Stack>
                )}
              </TabPanel>
              <TabPanel>
                <Box
                  mt="10px !important"
                  overflow="auto"
                  h="480px"
                  ref={divRef}
                  id="comment-box"
                >
                  {data &&
                    data.comments
                      .slice(0)
                      .reverse()
                      .map(comment => {
                        return (
                          <>
                            <Flex flex="1" alignItems="center" p="12px">
                              {comment.user.profile_picture ? (
                                <Image
                                  cursor="pointer"
                                  boxSize="40px"
                                  objectFit="cover"
                                  src={
                                    comment.user.profile_picture
                                      ? comment.user.profile_picture
                                      : ''
                                  }
                                  alt="Thumbnail"
                                  ignoreFallback
                                  borderRadius="full"
                                  mr="12px"
                                />
                              ) : (
                                <Icon as={FaUser} boxSize="40px" mr="12px" />
                              )}

                              <Box flex="1">
                                <HStack>
                                  <Text
                                    fontSize="16px"
                                    fontFamily="Roboto"
                                    fontWeight="600"
                                    color="#0e1111"
                                  >
                                    {comment.user.full_name}
                                  </Text>
                                  <Spacer />
                                  <Text
                                    fontSize="16px"
                                    fontFamily="Poppins"
                                    color="#212529"
                                  >
                                    {moment(comment.timestamp).format('LLL')}
                                  </Text>
                                </HStack>
                                <Text
                                  fontSize="16px"
                                  fontFamily="Poppins"
                                  color="#999999"
                                >
                                  {comment.comment}
                                </Text>
                              </Box>
                            </Flex>
                            <Divider />
                          </>
                        );
                      })}
                </Box>
                <HStack mt="20px !important">
                  <Input
                    placeholder="Add Comment"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      store
                        ._addComment({
                          id: appointment.appointment_id,
                          comment: comment,
                        })
                        .then(res => {
                          if (res) {
                            updateData();
                            setComment('');
                          }
                        });
                    }}
                  >
                    Send
                  </Button>
                </HStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        {type === 'mentee' ? (
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        ) : (
          <ModalFooter justifyContent="flex-start">
            {appointment && appointment.status_name === 'PENDING' && (
              <Button
                isLoading={loading}
                colorScheme="red"
                onClick={() => {
                  setLoading(true);
                  store
                    ._updateBooking({
                      id: appointment.appointment_id,
                      status: 'Rejected',
                    })
                    .then(res => {
                      setLoading(false);
                      if (res) onClose();
                    });
                }}
              >
                Reject
              </Button>
            )}
            <Spacer />
            {appointment && appointment.status_name === 'PENDING' && (
              <Button
                isLoading={loading}
                colorScheme="green"
                onClick={() => {
                  setLoading(true);
                  store
                    ._updateBooking({
                      id: appointment.appointment_id,
                      status: 'Accepted',
                    })
                    .then(res => {
                      setLoading(false);
                      if (res) onClose();
                    });
                }}
              >
                Accept
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default RequestInfoModal;
