/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Button,
  Icon,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Circle,
  Switch as CSwitch,
  Divider,
  Link as Anchor,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
} from '@chakra-ui/react';

import Panel from '../shared/Panel';

import { FaUser } from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const WebinarEdit = ({ data, isOpen, onClose, onSubmit, user_type }) => {
  const store = useContext(MainStore);

  const [title, settitle] = useState('');
  const [industry, setindustry] = useState('');
  const [seats, setseats] = useState('');
  const [desc, setdesc] = useState('');
  const [details, setdetails] = useState('');
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      //set data
      console.log('DATA!', data);
      if (data) {
        settitle(data.title);
        setindustry({
          value: String(data.industry.industry_id),
          label: data.industry.name,
        });
        setseats(data.seats);
        setdesc(data.short_desc);
        setdetails(data.description);
        setvisible(data.is_visible);
      }
    }
    return () => {};
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Webinar Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Stack
              pointerEvents={
                data &&
                data.webinar_status === 'PENDING' &&
                user_type === 'mentor'
                  ? 'none'
                  : 'initial'
              }
              opacity={
                data &&
                data.webinar_status === 'PENDING' &&
                user_type === 'mentor'
                  ? 0.75
                  : 1
              }
            >
              <FormControl isRequired>
                <Input
                  placeholder="Event Title"
                  value={title}
                  onChange={e => settitle(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <Select
                  placeholder="Select Industry"
                  onChange={e => setindustry(e)}
                  value={industry}
                  options={[
                    { value: '1', label: 'Corporate Finance/Investment' },
                    { value: '2', label: 'Banking/Financial' },
                    { value: '3', label: 'Clerical/Administrative' },
                    { value: '4', label: 'Secretarial' },
                    { value: '5', label: 'Business Planning' },
                    { value: '6', label: 'Blockchain' },
                    { value: '7', label: 'PR' },
                    { value: '8', label: 'StoryTelling' },
                    { value: '9', label: 'Business Development' },
                    { value: '10', label: 'Growth Hacking' },
                    { value: '11', label: 'Private Equity' },
                    { value: '12', label: 'Investor' },
                    { value: '13', label: 'Marketing' },
                    { value: '14', label: 'Product' },
                    { value: '15', label: 'Entreprenuer' },
                    { value: '16', label: 'Finance' },
                    { value: '17', label: 'Legal' },
                    { value: '18', label: 'Fund Raising' },
                    { value: '19', label: 'M&A' },
                    { value: '20', label: 'IoT' },
                    { value: '21', label: 'EdTech' },
                    { value: '22', label: 'Tech' },
                    { value: '23', label: 'Fintech' },
                    { value: '24', label: 'AI/ML' },
                    { value: '25', label: 'IP' },
                  ]}
                />
              </FormControl>

              <FormControl isRequired>
                <Input
                  placeholder="Seats"
                  value={seats}
                  onChange={e => setseats(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <Input
                  placeholder="Short Description"
                  value={desc}
                  onChange={e => setdesc(e.target.value)}
                />
              </FormControl>
              <ReactQuill
                theme="snow"
                value={details}
                onChange={value => setdetails(value)}
              />
              <HStack>
                <CSwitch
                  size="md"
                  isChecked={visible}
                  onChange={e => setvisible(e.target.checked)}
                />
                <Text>Webinar Publicly Available</Text>
              </HStack>
            </Stack>
          </form>
        </ModalBody>

        {data && data.webinar_status === 'PENDING' && user_type === 'mentor' ? (
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="red"
              onClick={() => {
                //reject
                store
                  ._updateWebinarStatus({
                    id: data.webinar_id,
                    status: 'rejected',
                  })
                  .then(res => {
                    if (res) {
                      //action
                    }
                  });
              }}
            >
              Reject
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                //accept
                store
                  ._updateWebinarStatus({
                    id: data.webinar_id,
                    status: 'accepted',
                  })
                  .then(res => {
                    if (res) {
                    }
                    onClose();
                    onSubmit();
                  });
              }}
            >
              Accept
            </Button>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              colorScheme="yellow"
              color="white"
              onClick={() => {
                store
                  ._getWebinar({
                    edit: true,
                    id: data.webinar_id,
                    title: title,
                    industry_id: parseInt(industry.value),
                    seats: seats,
                    short_desc: desc,
                    description: details,
                    is_visible: visible,
                  })
                  .then(res => {
                    if (res) {
                    }
                    onSubmit();
                    onClose();
                  });
              }}
            >
              Update Webinar
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default WebinarEdit;
