/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Button,
  Icon,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Circle,
  Switch as CSwitch,
  Divider,
  Link as Anchor,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
  Tag,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

import Panel from '../shared/Panel';

import { FaUser, FaCalendarAlt, FaRegClock, FaSearch } from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import ViewWebinar from './ViewWebinar';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const EventsScreen = ({ tab, action }) => {
  const store = useContext(MainStore);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState();

  const updateData = () => {
    store[action]().then(res => {
      if (res) {
        //process sorting and filtering here
        let sorted = res.data.sort((a, b) => {
          let timeA = moment
            .utc(a.start_time, 'HH:mm::ss')
            .local()
            .format('HH:mm:ss');
          let dateA = moment(`${a.date} ${timeA}`);
          let timeB = moment
            .utc(b.start_time, 'HH:mm::ss')
            .local()
            .format('HH:mm:ss');
          let dateB = moment(`${b.date} ${timeB}`);

          return dateA.valueOf() - dateB.valueOf();
        });

        //get past webinars
        let past = sorted.slice().filter(schedule => {
          return moment(
            `${schedule.date} ${moment
              .utc(schedule.start_time, 'HH:mm::ss')
              .local()
              .format('HH:mm:ss')}`
          ).isBefore(moment());
        });

        //get upcoming webinars
        let upcoming = sorted.slice().filter(schedule => {
          const temp = moment(
            `${schedule.date} ${moment
              .utc(schedule.start_time, 'HH:mm::ss')
              .local()
              .format('HH:mm:ss')}`
          );
          console.log(temp.format('LLL'), moment().format('LLL'));
          return temp.isSameOrAfter(moment());
        });

        if (store.debugMode) console.log('SORTED', sorted);

        let arranged = [...upcoming, ...past];
        arranged = arranged.filter(
          s =>
            s.webinar_status === 'ACCEPTED' || s.webinar_status === 'COMPLETED'
        );
        //filter to only show accepted
        setData(arranged);
        setFiltered(arranged);
      }
    });
  };

  const getTime = (d, t) => {
    let time = moment.utc(t, 'HH:mm::ss').local().format('HH:mm:ss');
    let date = moment(`${d} ${time}`).fromNow();
    return date;
  };

  useEffect(() => {
    updateData();
    return () => {};
  }, [tab]);

  useEffect(() => {
    if (search === '') {
      setFiltered([...data]);
    } else {
      const f = data
        .slice()
        .filter(d => JSON.stringify(d).toLowerCase().includes(search));
      setFiltered(f);
    }
    return () => {};
  }, [search]);

  return (
    <Stack spacing="12px">
      <ViewWebinar
        data={selected}
        isOpen={isOpen}
        onClose={onClose}
        onJoin={id => {
          store._joinWebinar({ webinar_id: id }).then(res => {
            if (res) {
              store._getIncomingEvent({ id: id }).then(res => {
                if (res) {
                  setSelected(res.data);
                  onClose();
                  setTimeout(onOpen, 250);
                }
              });
            }
          });
        }}
      />
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<FaSearch />} />
        <Input
          type="text"
          placeholder="Search"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            fontWeight="400"
            size="sm"
            onClick={() => setSearch('')}
          >
            Clear
          </Button>
        </InputRightElement>
      </InputGroup>

      <Stack spacing="12px">
        {filtered.map(d => {
          return (
            <Panel
              boxShadow="lg"
              opacity={
                moment(
                  `${d.date} ${moment
                    .utc(d.start_time, 'HH:mm::ss')
                    .local()
                    .format('HH:mm:ss')}`
                ).isSameOrAfter(moment())
                  ? 1
                  : 0.6
              }
            >
              <HStack px="24px" py="4px" h="34px">
                <Text
                  fontFamily="Source Sans Pro"
                  fontSize="18px"
                  fontWeight="bold"
                >
                  {d.title}
                </Text>
                {d.webinar_status === 'COMPLETED' && (
                  <Tag size="sm" colorScheme="blue" textTransform="capitalize">
                    {d.webinar_status.toLocaleLowerCase()}
                  </Tag>
                )}
                <Spacer />
                <Text
                  fontFamily="Source Sans Pro"
                  fontSize="16px"
                  color="#718096"
                  fontWeight="bold"
                >
                  {getTime(d.date, d.start_time)}
                </Text>
              </HStack>
              <Divider />
              <Flex flexDir={{ base: 'column', lg: 'row' }}>
                <VStack p={{ base: '20px 0px', lg: '40px 80px' }}>
                  {d.mentor.profile_picture ? (
                    <Image
                      cursor="pointer"
                      boxSize="110px"
                      objectFit="cover"
                      src={
                        d.mentor.profile_picture ? d.mentor.profile_picture : ''
                      }
                      alt="Thumbnail"
                      ignoreFallback
                      borderRadius="full"
                    />
                  ) : (
                    <Circle
                      boxSize="110px"
                      border="1px solid gray"
                      backgroundColor="white"
                      cursor="pointer"
                      _hover={{ opacity: 0.8 }}
                    >
                      <Icon as={FaUser} w={'55px'} h={'55px'} opacity="0.5" />
                    </Circle>
                  )}
                  <Text fontFamily="Source Sans Pro" fontSize="20px">
                    {d.mentor.full_name}
                  </Text>
                </VStack>
                <Stack
                  flex="1"
                  spacing="6px"
                  p={{ base: '10px 20px', lg: '40px 40px' }}
                >
                  <Box>
                    <Tag colorScheme="blue" size="md">
                      {d.industry && d.industry.name}
                    </Tag>
                  </Box>
                  <HStack>
                    <Icon as={FaCalendarAlt} size="xs" />
                    <Text>{moment(d.date).format('LL')}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FaRegClock} size="xs" />
                    <Text>{`${moment
                      .utc(d.start_time, 'HH:mm::ss')
                      .local()

                      .format('h:mm a')} - ${moment
                      .utc(d.end_time, 'HH:mm::ss')
                      .local()

                      .format('h:mm a')}`}</Text>
                  </HStack>
                  <HStack>
                    <Text>Available Seats:</Text>
                    <Tag colorScheme="blue">{`${d.available_seats}/${d.seats}`}</Tag>
                  </HStack>
                  <Text fontFamily="Roboto" fontSize="14px" color="#6c757d">
                    {d.short_desc}
                  </Text>
                  {/* <div dangerouslySetInnerHTML={{ __html: d.description }} /> */}
                </Stack>
              </Flex>
              <HStack justifyContent="flex-end" p="10px">
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    store._getIncomingEvent({ id: d.webinar_id }).then(res => {
                      if (res) {
                        setSelected(res.data);
                        onOpen();
                      }
                    });
                  }}
                >
                  Read More
                </Button>
              </HStack>
            </Panel>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default EventsScreen;
