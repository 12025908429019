/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const UserProfile = () => {
  const store = useContext(MainStore);
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  let { userID } = useParams();

  const [user, setuser] = useState();
  const [me, setme] = useState(false);

  useEffect(() => {
    if (store.userData && store.userData.user.id === parseInt(userID)) {
      setme(true);
    }

    store._getMember({ id: userID }).then(res => {
      if (store.debugMode) console.log(res.data);
      setuser(res.data);
    });

    return () => {};
  }, []);

  return (
    <Box p="12px">
      {user && (
        <Panel flex="2" w="100%" maxW="1024px" m="0 auto">
          <Flex
            alignItems="flex-start"
            p="32px"
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <VStack flex="1" w={{ base: '100%', lg: 'initial' }}>
              {user.profile_picture ? (
                <Image
                  boxSize="140px"
                  backgroundColor="grey"
                  borderRadius="full"
                  src={user.profile_picture}
                  objectFit="cover"
                />
              ) : (
                <Circle size="140px" bg="grey" color="black">
                  <Icon as={FaUser} boxSize="70px" circle />
                </Circle>
              )}
              <Box h="6px" />
              <Text
                fontSize="20px"
                fontFamily="Source Sans Pro"
                fontWeight="700"
              >
                {user.full_name}
              </Text>
              <Text textTransform="capitalize">{user.user_type}</Text>
            </VStack>
            <Stack flex="2" w={{ base: '100%', lg: 'initial' }}>
              <Stack>
                <HStack>
                  <Text
                    fontFamily="Source Sans Pro"
                    fontWeight="600"
                    fontSize="18px"
                    color="#444444"
                  >
                    Super Power
                  </Text>
                  <Spacer />
                  <IconButton
                    display="none"
                    variant="link"
                    _focus={{ outline: 'none' }}
                    icon={<FaEdit />}
                  ></IconButton>
                </HStack>
                <Divider />
                <Wrap>
                  {user.super_powers &&
                    user.super_powers.map((o, i) => {
                      return (
                        <WrapItem>
                          <Text>{`${o}${
                            i === user.super_powers.length - 1 ? '' : ','
                          }`}</Text>
                        </WrapItem>
                      );
                    })}
                </Wrap>
              </Stack>
              <Stack mt="16px !important">
                <HStack>
                  <Text
                    fontFamily="Source Sans Pro"
                    fontWeight="600"
                    fontSize="18px"
                    color="#444444"
                  >
                    Skills
                  </Text>
                  <Spacer />
                  <IconButton
                    display="none"
                    variant="link"
                    _focus={{ outline: 'none' }}
                    icon={<FaEdit />}
                  ></IconButton>
                </HStack>
                <Divider />
                <Wrap>
                  {user.skills &&
                    user.skills.map((o, i) => {
                      return (
                        <WrapItem>
                          <Text>{`${o}${
                            i === user.skills.length - 1 ? '' : ','
                          }`}</Text>
                        </WrapItem>
                      );
                    })}
                </Wrap>
              </Stack>
              <Stack mt="16px !important">
                <HStack>
                  <Text
                    fontFamily="Source Sans Pro"
                    fontWeight="600"
                    fontSize="18px"
                    color="#444444"
                  >
                    About Me
                  </Text>
                  <Spacer />
                  <IconButton
                    display="none"
                    variant="link"
                    _focus={{ outline: 'none' }}
                    icon={<FaEdit />}
                  ></IconButton>
                </HStack>
                <Divider />
                <Text>{user.about_me}</Text>
              </Stack>
              <Stack mt="16px !important">
                <HStack>
                  <Text
                    fontFamily="Source Sans Pro"
                    fontWeight="600"
                    fontSize="18px"
                    color="#444444"
                  >
                    Industry
                  </Text>
                  <Spacer />
                  <IconButton
                    display="none"
                    variant="link"
                    _focus={{ outline: 'none' }}
                    icon={<FaEdit />}
                  ></IconButton>
                </HStack>
                <Divider />
                <Wrap>
                  {user.industries &&
                    user.industries.map(o => {
                      return (
                        <WrapItem>
                          <Box
                            backgroundColor="#001f3f"
                            color="white"
                            px="12px"
                            py="6px"
                            borderRadius="3px"
                          >
                            <Text fontFamily="Poppins">{o}</Text>
                          </Box>
                        </WrapItem>
                      );
                    })}
                </Wrap>
              </Stack>
            </Stack>
          </Flex>
        </Panel>
      )}
    </Box>
  );
};

export default UserProfile;
