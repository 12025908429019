/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import MentorList from './MentorList';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const RequestMentoring = () => {
  let history = useHistory();
  const store = useContext(MainStore);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mentor, setMentor] = useState('');
  const [duration, setDuration] = useState('');
  const [date, setDate] = useState(new Date());
  const [schedID, setSchedID] = useState([]);
  const [available, setAvailable] = useState([]);

  const [slot, setSlot] = useState('');
  const [title, setTitle] = useState('');
  const [topic, setTopic] = useState('');
  const [t1, setT1] = useState('');
  const [t2, setT2] = useState('');
  const [t3, setT3] = useState('');

  const [loading, setLoading] = useState(false);
  const [file, setfile] = useState([]);

  const [mentorOpen, setMentorOpen] = useState(false);

  const getMentorSchedule = () => {
    if (store.debugMode) console.log(date, duration, mentor);
    if (!mentor || !duration) {
      store._globalToast({
        title: 'Please select Mentor and Duration',
        status: 'warning',
      });
      return;
    }

    store
      ._getMentorSchedule({ date, duration, mentor_id: mentor.id })
      .then(res => {
        if (res) {
          setSchedID(res.schedule_id);

          //get my time in UTC and compare with the schedules
          if (store.debugMode) console.log('NOW', moment.utc().format('LLL'));
          //loop through schedules and compare
          const filtered = res.schedules.filter(schedule => {
            let time = moment
              .utc(schedule.from, 'HH:mm::ss')
              .local()
              .format('HH:mm:ss');

            const formatDate = moment(date);
            let d = moment(`${formatDate.format('YYYY-MM-DD')} ${time}`);

            // schedule.fromNow = d.isAfter();
            schedule.fromNow = d.fromNow();

            return d.isAfter();

            // return schedule;
          });

          setAvailable(filtered);
        } else {
          store._globalToast({
            title: 'No available slots found',
            status: 'error',
          });
        }
      });
  };

  const onFilesChange = files => {
    store._globalToast({
      title: 'File loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    if (store.debugMode) console.log('FILES!!!', files);

    setfile(files);
  };

  const onFilesError = (error, file) => {
    if (store.debugMode)
      console.log('error code ' + error.code + ': ' + error.message);
  };

  useEffect(() => {
    if (isOpen) {
      //clear
      setTitle('');
      setTopic('');
      setT1('');
      setT2('');
      setT3('');
      setLoading(false);
    }
    return () => {};
  }, [isOpen]);

  return (
    <Box p="12px">
      <HStack>
        <Button onClick={() => history.goBack()}>Back</Button>
      </HStack>

      <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
        <ModalOverlay />

        <ModalContent>
          <form
            onSubmit={e => {
              e.preventDefault();

              setLoading(true);
              store
                ._bookRequest({
                  mentor_id: mentor.id,
                  schedule_id: schedID,
                  date_schedule: moment(date).format('YYYY-MM-DD'),
                  duration,
                  start_time: moment
                    .utc(slot.from, 'HH:mm::ss')
                    .format('hh:mm a'),
                  end_time: moment.utc(slot.to, 'HH:mm::ss').format('hh:mm a'),
                  title,
                  topics: topic,
                  targets: [
                    { target_1: t1 },
                    { target_2: t2 },
                    { target_3: t3 },
                  ],
                  files: file,
                })
                .then(res => {
                  setLoading(false);
                  if (res) {
                    onClose();
                    history.goBack();
                  } else {
                  }
                });
            }}
          >
            <ModalHeader>Request Mentoring</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Stack spacing="10px">
                <Text>{`Mentor: ${mentor.first_name} ${mentor.last_name}`}</Text>
                <Text>{`Super Powers: ${
                  mentor.super_powers ? mentor.super_powers.join(', ') : ''
                }`}</Text>
                <Text>{`Schedule: ${moment(date).format('LL')} at ${moment
                  .utc(slot.from, 'HH:mm::ss')
                  .local()
                  .format('HH:mm')} - ${moment
                  .utc(slot.to, 'HH:mm::ss')
                  .local()
                  .format('HH:mm')} `}</Text>

                <Divider />
                <FormControl isRequired>
                  <Input
                    placeholder="Startup"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="Topic"
                    value={topic}
                    onChange={e => setTopic(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="Target 1"
                    value={t1}
                    onChange={e => setT1(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="Target 2"
                    value={t2}
                    onChange={e => setT2(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="Target 3"
                    value={t3}
                    onChange={e => setT3(e.target.value)}
                  />
                </FormControl>
                <Files
                  className="files-dropzone"
                  onChange={files => onFilesChange(files)}
                  onError={onFilesError}
                  // accepts={['image/*']}
                  multiple={true}
                  // maxFileSize={10000000}
                  // minFileSize={0}
                  clickable
                >
                  <Button leftIcon={<FaPaperclip />} variant="ghost">
                    Attach Files
                  </Button>
                </Files>
                <Box>
                  {file.length > 0 && (
                    <Text fontSize="12px" fontWeight="bold">
                      Attachments:
                    </Text>
                  )}
                  {file.map((f, i) => {
                    return (
                      <Box mt="8px">
                        <Tag
                          size={'md'}
                          key={'md'}
                          borderRadius="full"
                          variant="subtle"
                          colorScheme="blue"
                        >
                          <TagLabel>{f.name}</TagLabel>
                          <TagCloseButton
                            _focus={{ boxShadow: 'none' }}
                            onClick={() => {
                              file.splice(i, 1);
                              setfile([...file]);
                            }}
                          />
                        </Tag>
                      </Box>
                    );
                  })}
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                }}
                mr={2}
              >
                Cancel
              </Button>
              <Button isLoading={loading} colorScheme="blue" type="submit">
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Flex mt="12px !important" flexDir={{ base: 'column', lg: 'row' }}>
        <Stack flex="1" spacing={'10px'}>
          <Panel>
            <Box p="12px">
              <Text
                fontWeight="Source Sans Pro"
                fontSize="20px"
                fontWeight="700"
              >
                Request Mentoring
              </Text>
              <Text color="#999999" fontSize="14px" lineHeight="10px">
                Choose Below
              </Text>
              <Divider my="10px" />
              <Button onClick={() => setMentorOpen(true)}>Select Mentor</Button>
              <MentorList
                setMentor={setMentor}
                isOpen={mentorOpen}
                onClose={() => setMentorOpen(false)}
              />
              <Stack>
                <Text
                  fontWeight="Source Sans Pro"
                  fontSize="20px"
                  fontWeight="700"
                >
                  Mentor
                </Text>
                {mentor && mentor.profile_picture ? (
                  <Image
                    boxSize="60px"
                    backgroundColor="grey"
                    borderRadius="full"
                    src={mentor.profile_picture}
                    objectFit="cover"
                  />
                ) : (
                  <Circle size="60px" bg="grey" color="black">
                    <Icon as={FaUser} boxSize="18px" circle />
                  </Circle>
                )}
                <Text fontFamily="Source Sans pro" fontWeight="600">{`${
                  mentor ? mentor.first_name + ' ' + mentor.last_name : ''
                }`}</Text>
                <Text>{`${
                  mentor && mentor.super_powers
                    ? mentor.super_powers.join(', ')
                    : ''
                }`}</Text>
              </Stack>
            </Box>
          </Panel>

          <Panel>
            <Stack p="12px">
              <Text
                fontWeight="Source Sans Pro"
                fontSize="20px"
                fontWeight="700"
              >
                {`Duration & Schedule`}
              </Text>
              <Text color="#999999" fontSize="14px" lineHeight="10px">
                Set your duration and date
              </Text>
              <Divider my="10px" />
              <SimpleGrid my="10px" columns={4} spacing="8px">
                <Button
                  backgroundColor="#032349"
                  color="white"
                  colorScheme="blue"
                  _focus={{ boxShadow: '0 0 0 3px #FBC02E' }}
                  onClick={() => setDuration(15)}
                >
                  15
                </Button>
                <Button
                  backgroundColor="#032349"
                  color="white"
                  colorScheme="blue"
                  _focus={{ boxShadow: '0 0 0 3px #FBC02E' }}
                  onClick={() => setDuration(30)}
                >
                  30
                </Button>
                <Button
                  backgroundColor="#032349"
                  color="white"
                  colorScheme="blue"
                  _focus={{ boxShadow: '0 0 0 3px #FBC02E' }}
                  onClick={() => setDuration(45)}
                >
                  45
                </Button>
                <Button
                  backgroundColor="#032349"
                  color="white"
                  colorScheme="blue"
                  _focus={{ boxShadow: '0 0 0 3px #FBC02E' }}
                  onClick={() => setDuration(60)}
                >
                  60
                </Button>
                <Button
                  backgroundColor="#032349"
                  color="white"
                  colorScheme="blue"
                  _focus={{ boxShadow: '0 0 0 3px #FBC02E' }}
                  onClick={() => setDuration(90)}
                >
                  90
                </Button>
                <Button
                  backgroundColor="#032349"
                  color="white"
                  colorScheme="blue"
                  _focus={{ boxShadow: '0 0 0 3px #FBC02E' }}
                  onClick={() => setDuration(120)}
                >
                  120
                </Button>
              </SimpleGrid>

              <Text color="#999999" fontSize="14px" lineHeight="10px">
                Selected Duration
              </Text>
              <Text
                fontWeight="Source Sans Pro"
                fontSize="20px"
                fontWeight="700"
              >
                {duration}
              </Text>
              <Text
                fontWeight="Source Sans Pro"
                fontSize="16px"
                fontWeight="700"
              >
                Select Date
              </Text>
              <Flatpickr
                options={{
                  altInput: true,
                  altFormat: 'F j, Y',
                  dateFormat: 'Y-m-d',
                }}
                value={date}
                onChange={d => {
                  if (store.debugMode) console.log(d[0]);
                  setDate(d[0]);
                }}
                render={({ defaultValue, value, ...props }, ref) => {
                  return (
                    <Input
                      isRequired
                      placeholder="Set Start Date"
                      {...props}
                      ref={ref}
                    />
                  );
                }}
              />
            </Stack>
          </Panel>
        </Stack>

        <Panel
          flex="3"
          ml={{ base: '0px', lg: '10px' }}
          mt={{ base: '10px', lg: '0px' }}
        >
          <Box p="12px">
            <Text fontWeight="Source Sans Pro" fontSize="20px" fontWeight="700">
              Scheduler
            </Text>
            <Text color="#999999" fontSize="14px" lineHeight="10px">
              Choose Below
            </Text>
            <Divider my="10px" />

            <Button onClick={() => getMentorSchedule()}>Find Schedule</Button>
            <Box mt="10px">
              <Text fontWeight="bold">{`Available slots for ${moment(
                date
              ).format('LL')}`}</Text>
            </Box>
            <SimpleGrid mt="10px" columns={{ base: 1, lg: 2 }} spacing="10px">
              {available.map((a, i) => {
                return (
                  <Button
                    onClick={() => {
                      setSlot(a);
                      onOpen();
                    }}
                    isDisabled={!a.is_available}
                    isFullWidth
                  >
                    {`${moment
                      .utc(a.from, 'HH:mm::ss')
                      .local()
                      .format('h:mm a')} - ${moment
                      .utc(a.to, 'HH:mm::ss')
                      .local()
                      .format('h:mm a')} - ${a.fromNow}`}
                  </Button>
                );
              })}
            </SimpleGrid>
          </Box>
        </Panel>
      </Flex>
    </Box>
  );
};

export default RequestMentoring;
