/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Button,
  Icon,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Circle,
  Switch as CSwitch,
  Divider,
  Link as Anchor,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
  Tag,
} from '@chakra-ui/react';

import Panel from '../shared/Panel';

import {
  FaUser,
  FaCalendarAlt,
  FaRegClock,
  FaUsers,
  FaVideo,
} from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const ViewWebinar = ({ data, isOpen, onClose, onJoin }) => {
  const store = useContext(MainStore);
  const [type, settype] = useState('NONE');

  useEffect(() => {
    if (isOpen) {
      //set data
      if (store.debugMode) console.log('DATA!', data);
      settype('NONE');
      //check if I am the speaker or if I'm a participant
      const found = data.participants.find(
        p => p.user.id === store.userData.user.id
      );
      if (found) {
        if (found.participant_type === 'JOINER') {
          settype('PARTICIPANT');
        } else {
          settype(found.participant_type);
        }
      }
    }
    return () => {};
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Text>{`Event Details: ${data && data.title}`}</Text>

            {data && data.webinar_status === 'COMPLETED' && (
              <Tag size="sm" colorScheme="blue" textTransform="capitalize">
                {data.webinar_status.toLocaleLowerCase()}
              </Tag>
            )}
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data && (
            <Box>
              <HStack>
                {/* <Text>{`Event Details`}</Text> */}
                <Spacer />
                <HStack>
                  <Icon as={FaCalendarAlt} size="xs" color="#ffc107" />
                  <Text>{moment(data.date).format('LL')}</Text>
                </HStack>
                <HStack>
                  <Icon as={FaRegClock} size="xs" color="#ffc107" />
                  <Text>{`${moment
                    .utc(data.start_time, 'HH:mm::ss')
                    .local()
                    .format('h:mm a')} - ${moment
                    .utc(data.end_time, 'HH:mm::ss')
                    .local()
                    .format('h:mm a')}`}</Text>
                </HStack>
              </HStack>
              <Flex flexDir={{ base: 'column', lg: 'row' }}>
                <VStack p={{ base: '20px 20px', lg: '20px 20px' }}>
                  {data.participants[0].user.profile_picture ? (
                    <Image
                      // cursor="pointer"
                      boxSize="110px"
                      objectFit="cover"
                      src={
                        data.participants[0].user.profile_picture
                          ? data.participants[0].user.profile_picture
                          : ''
                      }
                      alt="Thumbnail"
                      ignoreFallback
                      borderRadius="full"
                    />
                  ) : (
                    <Circle
                      boxSize="110px"
                      border="1px solid gray"
                      backgroundColor="white"
                      // cursor="pointer"
                      // _hover={{ opacity: 0.8 }}
                    >
                      <Icon as={FaUser} w={'55px'} h={'55px'} opacity="0.5" />
                    </Circle>
                  )}
                  <Text fontFamily="Source Sans Pro" fontSize="20px">
                    {data.participants[0].user.full_name}
                  </Text>
                  <Text>{data.industry && data.industry.name}</Text>
                  {type === 'NONE' ? (
                    <>
                      {data && data.webinar_status === 'ACCEPTED' && (
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => {
                            onJoin(data.webinar_id);
                          }}
                        >
                          Join Now!
                        </Button>
                      )}
                    </>
                  ) : (
                    <Tag colorScheme="blue" textTransform="capitalize">
                      {type.toLocaleLowerCase()}
                    </Tag>
                  )}
                </VStack>
                <Stack
                  flex="1"
                  spacing="6px"
                  p={{ base: '20px 20px', lg: '20px 20px' }}
                >
                  <HStack>
                    <Text>Available Seats:</Text>
                    <Tag colorScheme="blue">{`${data.available_seats}/${data.seats}`}</Tag>
                  </HStack>
                  <Text fontFamily="Roboto" fontSize="14px" color="#6c757d">
                    {data.short_desc}
                  </Text>
                  <Box p="12px">
                    <div
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  </Box>
                </Stack>
              </Flex>
              <Divider />
              <HStack justifyContent="center" p="12px">
                <Icon as={FaUsers} size="lg" color="#007bff" />
                <Text
                  fontFamily="Source Sans Pro"
                  fontSize="24px"
                  color="#007bff"
                >
                  Participants
                </Text>
              </HStack>
              <SimpleGrid columns={3}>
                {data &&
                  data.participants.slice(1).map(p => {
                    return (
                      <VStack>
                        {p.user.profile_picture ? (
                          <Image
                            cursor="pointer"
                            boxSize="50px"
                            objectFit="cover"
                            src={
                              p.user.profile_picture
                                ? p.user.profile_picture
                                : ''
                            }
                            alt="Thumbnail"
                            ignoreFallback
                            borderRadius="full"
                          />
                        ) : (
                          <Circle
                            boxSize="50px"
                            border="1px solid gray"
                            backgroundColor="white"
                            cursor="pointer"
                            _hover={{ opacity: 0.8 }}
                          >
                            <Icon
                              as={FaUser}
                              w={'25px'}
                              h={'25px'}
                              opacity="0.5"
                            />
                          </Circle>
                        )}
                        <Text fontFamily="Poppins" fontSize="14px">
                          {p.user.full_name}
                        </Text>
                      </VStack>
                    );
                  })}
              </SimpleGrid>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          {type !== 'NONE' && (
            <Button
              leftIcon={<FaVideo />}
              colorScheme="yellow"
              color="white"
              onClick={() => {
                store
                  ._webinar({
                    action: type === 'SPEAKER' ? 'start' : 'join',
                    webinar_id: data.webinar_id,
                  })
                  .then(res => {
                    if (store.debugMode) console.log('BBB', res);
                    if (res) {
                      if (res.url === 'not started') {
                        store._globalToast({
                          title: 'Speaker has not started the meeting',
                          status: 'error',
                        });
                      } else {
                        onClose();
                        window.open(res.url);
                      }
                    }
                  });
              }}
            >
              {type === 'SPEAKER' ? 'Start Meeting' : 'Join Meeting'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewWebinar;
