/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import FeaturedInsights from './FeaturedInsights';
import FeaturedPitches from './FeaturedPitches';
import FeaturedCareers from './FeaturedCareers';
// import FeaturedMentors from './FeaturedMentors';
import FeaturedMembers from './FeaturedMembers';
import MentorSpotlights from './MentorSpotlights';
import RequestInfoModal from './RequestInfoModal';
import NewWebinarScreen from './NewWebinarScreen';
import MentorList from './MentorList';
import WebinarScreen from './WebinarScreen';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const PlayBookScreen = observer(() => {
  const store = useContext(MainStore);

  const exSum = useRef(null);
  const valueProp = useRef(null);
  const custPer = useRef(null);
  const prod = useRef(null);
  const targ = useRef(null);
  const goto = useRef(null);
  const sales = useRef(null);
  const fore = useRef(null);
  const inv = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState({});
  const [data, setData] = useState();

  useEffect(() => {
    let found;

    if (selected && selected.current) {
      const title = selected.current.getAttribute('name');
      found = store.playBooks.find(p => p.title === title);
      if (found) {
        //check extensions
        const extension = found.url.substring(found.url.lastIndexOf('.') + 1);
        if (['docx', 'ppt', 'pptx', 'xlsx'].includes(extension)) {
          found.type = 'ms';
        }
        if (extension === 'pdf') {
          found.type = 'pdf';
        }
        if (extension === 'zip') {
          found.type = 'zip';
        }
        setData({ ...found });
      } else {
        setData({ title, description: 'Not Available', url: '' });
      }
    }

    return () => {};
  }, [selected]);

  const downloadFile = name => {
    const found = store.playBooks.find(p => p.title === name);
    if (found) {
      window.open(found.url);
    } else {
      store._globalToast({
        title: 'File not available',
        status: 'warning',
      });
    }
  };

  return (
    <Box p="12px">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{data && data.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data && (
              <Box>
                {data.type === 'ms' ? (
                  <Box h="500px">
                    <iframe
                      style={{ width: '100%', height: '100%' }}
                      src={`https://view.officeapps.live.com/op/view.aspx?src=${data.url}`}
                    />
                  </Box>
                ) : (
                  <>
                    {data.type === 'pdf' ? (
                      <Text>Click Download to open PDF in a new tab.</Text>
                    ) : (
                      <Text>{`${data.title} ZIP is ready for download.`}</Text>
                    )}
                  </>
                )}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="yellow"
              color="white"
              onClick={() => {
                if (data) window.open(data.url);
              }}
            >
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Panel>
        <SimpleGrid columns={{ base: 1, lg: 2 }}>
          <Box p="20px">
            <Text fontFamily="Source Sans Pro" fontSize="28px" fontWeight="700">
              Startup Playbook
            </Text>
          </Box>
          <Box p="20px">
            <Text fontFamily="Roboto">
              Building a foundation for your startup is key, so you can focus on
              your business. Access HQ's 10+ proprietary templates & follow-up
              mentorship options.
            </Text>
            <Box mt="20px">
              <Text fontFamily="Roboto">
                Note: every startup is different and work through these
                templates with your team.
              </Text>
            </Box>
          </Box>
        </SimpleGrid>
      </Panel>
      <Box my="20px">
        <Text
          textAlign="center"
          fontFamily="Source Sans Pro"
          fontSize="28px"
          fontWeight="700"
        >
          SILICON VALLEY HQ'S CANVAS
        </Text>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 5 }} spacing="10px">
        <Box h="250px">
          <Box
            h="100%"
            backgroundColor={selected === exSum ? '#ffc107' : 'black'}
            color={selected === exSum ? 'black' : 'white'}
            borderRadius="md"
            p="16px"
            ref={exSum}
            onClick={() => setSelected(exSum)}
            cursor="pointer"
            fontSize="14px"
            name="Executive Summary"
          >
            Executive Summary
          </Box>
        </Box>
        <Box h="250px">
          <Box h="50%" pb="5px">
            <Box
              h="100%"
              backgroundColor={selected === valueProp ? '#ffc107' : 'black'}
              color={selected === valueProp ? 'black' : 'white'}
              borderRadius="md"
              p="16px"
              ref={valueProp}
              onClick={() => setSelected(valueProp)}
              cursor="pointer"
              fontSize="14px"
              name="Value Proposition"
            >
              Value Proposition
            </Box>
          </Box>
          <Box h="50%" pt="5px">
            <Box
              h="100%"
              backgroundColor={selected === custPer ? '#ffc107' : 'black'}
              color={selected === custPer ? 'black' : 'white'}
              borderRadius="md"
              p="16px"
              ref={custPer}
              onClick={() => setSelected(custPer)}
              cursor="pointer"
              fontSize="14px"
              name="Customer Personas"
            >
              Customer Personas
            </Box>
          </Box>
        </Box>
        <Box h="250px">
          <Box
            h="100%"
            backgroundColor={selected === prod ? '#ffc107' : 'black'}
            color={selected === prod ? 'black' : 'white'}
            borderRadius="md"
            p="16px"
            ref={prod}
            onClick={() => setSelected(prod)}
            cursor="pointer"
            fontSize="14px"
            name="Product"
          >
            Product
          </Box>
        </Box>
        <Box h="250px">
          <Box h="50%" pb="5px">
            <Box
              h="100%"
              backgroundColor={selected === targ ? '#ffc107' : 'black'}
              color={selected === targ ? 'black' : 'white'}
              borderRadius="md"
              p="16px"
              ref={targ}
              onClick={() => setSelected(targ)}
              cursor="pointer"
              fontSize="14px"
              name="Targets / KPIs"
            >
              Targets / KPIs
            </Box>
          </Box>
          <Box h="50%" pt="5px">
            <Box
              h="100%"
              backgroundColor={selected === goto ? '#ffc107' : 'black'}
              color={selected === goto ? 'black' : 'white'}
              borderRadius="md"
              p="16px"
              ref={goto}
              onClick={() => setSelected(goto)}
              cursor="pointer"
              fontSize="14px"
              name="GoToMarket"
            >
              GoToMarket
            </Box>
          </Box>
        </Box>
        <Box h="250px">
          <Box
            h="100%"
            backgroundColor={selected === sales ? '#ffc107' : 'black'}
            color={selected === sales ? 'black' : 'white'}
            borderRadius="md"
            p="16px"
            ref={sales}
            onClick={() => setSelected(sales)}
            cursor="pointer"
            fontSize="14px"
            name="Sales Strategy"
          >
            Sales Strategy
          </Box>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        mt="10px !important"
        columns={{ base: 1, md: 2 }}
        spacing="10px"
      >
        <Box h="55px">
          <Box
            h="100%"
            backgroundColor={selected === fore ? '#ffc107' : 'black'}
            color={selected === fore ? 'black' : 'white'}
            borderRadius="md"
            p="16px"
            ref={fore}
            onClick={() => setSelected(fore)}
            cursor="pointer"
            fontSize="14px"
            name="Forecasts"
          >
            Forecasts
          </Box>
        </Box>
        <Box h="55px">
          <Box
            h="100%"
            backgroundColor={selected === inv ? '#ffc107' : 'black'}
            color={selected === inv ? 'black' : 'white'}
            borderRadius="md"
            p="16px"
            ref={inv}
            onClick={() => setSelected(inv)}
            cursor="pointer"
            fontSize="14px"
            name="Investor Deck"
          >
            Investor Deck
          </Box>
        </Box>
      </SimpleGrid>
      <Panel mt="40px">
        <SimpleGrid columns={{ base: 1, lg: 3 }}>
          <Box p="20px">
            <Text fontFamily="Source Sans Pro" fontSize="20px" fontWeight="700">
              {data && data.title}
            </Text>
          </Box>
          <Box p="20px">
            <Text fontFamily="Roboto">{data && data.description}</Text>
            <Box mt="20px">
              <Text fontFamily="Roboto">
                Note: every startup is different and work through these
                templates with your team.
              </Text>
            </Box>
          </Box>
          <Box p="20px">
            <Button
              isDisabled={
                data === null || (data && data.description === 'Not Available')
                  ? true
                  : false
              }
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                onOpen();
              }}
            >
              View/Download Template
            </Button>
            <Button
              isDisabled={true}
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
            >
              Download Mentee's Document
            </Button>
            <Button
              isDisabled={true}
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
            >
              Comments Area
            </Button>
          </Box>
        </SimpleGrid>
      </Panel>
      <Panel mt="40px">
        <Box p="20px">
          <Text fontFamily="Source Sans Pro" fontSize="20px" fontWeight="700">
            {`Additional Templates from the Internet that may be useful:`}
          </Text>
          <SimpleGrid mt="10px" columns={{ base: 1, lg: 3 }} spacingX="10px">
            <Button
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                downloadFile('How funding works Infographic');
              }}
            >
              How funding works Infographic
            </Button>
            <Button
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                downloadFile('Strategy');
              }}
            >
              Strategy
            </Button>
            <Button
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                downloadFile('Balance Sheet');
              }}
            >
              Balance Sheet
            </Button>
            <Button
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                downloadFile('Financial report');
              }}
            >
              Financial report
            </Button>
            <Button
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                downloadFile('Valuation Methodology');
              }}
            >
              Valuation Methodology
            </Button>
            <Button
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                downloadFile('Business Plan');
              }}
            >
              Business Plan
            </Button>
            <Button
              borderRadius="0"
              border="1px solid gold"
              colorScheme="yellow"
              color="white"
              isFullWidth
              onClick={() => {
                downloadFile('Cap Table');
              }}
            >
              Cap Table
            </Button>
          </SimpleGrid>
        </Box>
      </Panel>
    </Box>
  );
});

export default PlayBookScreen;
