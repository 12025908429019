/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import MentorList from './MentorList';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const EditMySchedule = observer(() => {
  let history = useHistory();
  const store = useContext(MainStore);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [from, setFrom] = useState('8:00');
  const [to, setTo] = useState('17:00');
  const [days, setDays] = useState([]);

  const clearFields = () => {
    setFrom('8:00');
    setTo('17:00');
    setDays([]);
  };

  useEffect(() => {
    if (isOpen) clearFields();
    return () => {};
  }, [isOpen]);

  return (
    <Box p="12px">
      {store.userData && store.userData.user.user_type === 'mentor' && (
        <>
          <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Set Time Availability</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <HStack spacing="10px">
                  <Box>
                    <Text>FROM</Text>
                    <TimePicker
                      disableClock={true}
                      onChange={setFrom}
                      value={from}
                    />
                  </Box>
                  <Box>
                    <Text>TO</Text>
                    <TimePicker
                      disableClock={true}
                      onChange={setTo}
                      value={to}
                    />
                  </Box>
                </HStack>
                <Box mt="10px">
                  <CheckboxGroup
                    colorScheme="blue"
                    value={days}
                    onChange={setDays}
                  >
                    <Stack>
                      <Checkbox value="Sunday">Sunday</Checkbox>
                      <Checkbox value="Monday">Monday</Checkbox>
                      <Checkbox value="Tuesday">Tuesday</Checkbox>
                      <Checkbox value="Wednesday">Wednesday</Checkbox>
                      <Checkbox value="Thursday">Thursday</Checkbox>
                      <Checkbox value="Friday">Friday</Checkbox>
                      <Checkbox value="Saturday">Saturday</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="yellow"
                  color="white"
                  onClick={() => {
                    if (store.debugMode) console.log(to, from, days);
                    store._setSchedule({ to, from, days }).then(res => {
                      onClose();
                    });
                  }}
                >
                  Set Time
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Box>
            <HStack>
              <Button onClick={() => history.goBack()}>Back</Button>
              <Text
                fontFamily="Source Sans Pro"
                fontSize="24px"
                fontWeight="600"
              >
                Mentor Schedule
              </Text>
            </HStack>
          </Box>

          <Panel mt="12px">
            <Box p="12px">
              <Button colorScheme="yellow" color="white" onClick={onOpen}>
                Set a new Schedule
              </Button>
              <Stack spacing="4px" p="10px">
                {store.mentorSchedules.map((s, i) => {
                  return (
                    <>
                      <Box p="10px">
                        <Flex alignItems="center">
                          <Text
                            fontFamily="Source Sans Pro"
                            fontSize="20px"
                            fontWeight="700"
                          >{`${moment
                            .utc(s.start_time, 'HH:mm::ss')

                            .local()
                            .format('hh:mm a')} - ${moment
                            .utc(s.end_time, 'HH:mm::ss')

                            .local()
                            .format('hh:mm a')}`}</Text>
                        </Flex>
                        <Flex
                          alignItems="center"
                          mt="8px !important"
                          fontFamily="Poppins"
                          fontWeight="500"
                        >
                          <Text
                            px="20px"
                            py="12px"
                            border="1px solid rgba(0,0,0,.125)"
                            borderTopLeftRadius="md"
                            borderBottomLeftRadius="md"
                            color={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'SUNDAY'
                              )
                                ? 'white'
                                : 'black'
                            }
                            backgroundColor={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'SUNDAY'
                              )
                                ? '#007BFF'
                                : 'transparent'
                            }
                          >
                            SUN
                          </Text>
                          <Text
                            px="20px"
                            py="12px"
                            border="1px solid rgba(0,0,0,.125)"
                            color={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'MONDAY'
                              )
                                ? 'white'
                                : 'black'
                            }
                            backgroundColor={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'MONDAY'
                              )
                                ? '#007BFF'
                                : 'transparent'
                            }
                          >
                            MON
                          </Text>
                          <Text
                            px="20px"
                            py="12px"
                            border="1px solid rgba(0,0,0,.125)"
                            color={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'TUESDAY'
                              )
                                ? 'white'
                                : 'black'
                            }
                            backgroundColor={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'TUESDAY'
                              )
                                ? '#007BFF'
                                : 'transparent'
                            }
                          >
                            TUE
                          </Text>
                          <Text
                            px="20px"
                            py="12px"
                            border="1px solid rgba(0,0,0,.125)"
                            color={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'WEDNESDAY'
                              )
                                ? 'white'
                                : 'black'
                            }
                            backgroundColor={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'WEDNESDAY'
                              )
                                ? '#007BFF'
                                : 'transparent'
                            }
                          >
                            WED
                          </Text>
                          <Text
                            px="20px"
                            py="12px"
                            border="1px solid rgba(0,0,0,.125)"
                            color={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'THURSDAY'
                              )
                                ? 'white'
                                : 'black'
                            }
                            backgroundColor={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'THURSDAY'
                              )
                                ? '#007BFF'
                                : 'transparent'
                            }
                          >
                            THU
                          </Text>
                          <Text
                            px="20px"
                            py="12px"
                            border="1px solid rgba(0,0,0,.125)"
                            color={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'FRIDAY'
                              )
                                ? 'white'
                                : 'black'
                            }
                            backgroundColor={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'FRIDAY'
                              )
                                ? '#007BFF'
                                : 'transparent'
                            }
                          >
                            FRI
                          </Text>
                          <Text
                            px="20px"
                            py="12px"
                            border="1px solid rgba(0,0,0,.125)"
                            color={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'SATURDAY'
                              )
                                ? 'white'
                                : 'black'
                            }
                            backgroundColor={
                              s.recurring_day_schedules.find(
                                d => d.day_name === 'SATURDAY'
                              )
                                ? '#007BFF'
                                : 'transparent'
                            }
                          >
                            SAT
                          </Text>
                          <Spacer />
                          <IconButton
                            colorScheme="red"
                            ml="10px"
                            icon={<FaTrash />}
                            onClick={() =>
                              store._deleteSchedule({ id: s.schedule_id })
                            }
                          />
                        </Flex>
                      </Box>
                      <Divider />
                    </>
                  );
                })}
              </Stack>
            </Box>
          </Panel>
        </>
      )}
    </Box>
  );
});

export default EditMySchedule;
