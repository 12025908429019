import { Box } from '@chakra-ui/react';

const Panel = props => {
  return (
    <Box
      {...props}
      borderTop="3px solid #fbc02d"
      borderRadius="3px"
      boxShadow="xs"
      backgroundColor="#fff"
    >
      {props.children}
    </Box>
  );
};

export default Panel;
