/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import WebinarEdit from './WebinarEdit';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const WebinarAdminView = ({ tab, user_type }) => {
  const store = useContext(MainStore);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState();

  const updateData = () => {
    if (user_type === 'admin') {
      store._getWebinars().then(res => {
        if (res) {
          // setData(res.data);

          let sorted = res.data.sort((a, b) => {
            let timeA = moment
              .utc(a.start_time, 'HH:mm::ss')
              .local()
              .format('HH:mm:ss');
            let dateA = moment(`${a.date} ${timeA}`);
            let timeB = moment
              .utc(b.start_time, 'HH:mm::ss')
              .local()
              .format('HH:mm:ss');
            let dateB = moment(`${b.date} ${timeB}`);

            return dateA.valueOf() - dateB.valueOf();
          });

          setData(sorted);

          // setData(
          //   sorted.sort((a, b) =>
          //     a.webinar_status > b.webinar_status ? 1 : -1
          //   )
          // );
        }
      });
    } else {
      let merged = [];
      store._getWebinarRequests({ status: 'pending' }).then(res => {
        // if (res) setData(res.data);
        if (res) {
          merged.push(...res.data);
          store._getWebinarRequests({ status: 'accepted' }).then(res => {
            if (res) {
              merged.push(...res.data);

              let sorted = merged.sort((a, b) => {
                let timeA = moment
                  .utc(a.start_time, 'HH:mm::ss')
                  .local()
                  .format('HH:mm:ss');
                let dateA = moment(`${a.date} ${timeA}`);
                let timeB = moment
                  .utc(b.start_time, 'HH:mm::ss')
                  .local()
                  .format('HH:mm:ss');
                let dateB = moment(`${b.date} ${timeB}`);

                return dateA.valueOf() - dateB.valueOf();
              });

              sorted = sorted.sort((a, b) =>
                a.webinar_status > b.webinar_status ? 1 : -1
              );

              setData(sorted);
              setFiltered(sorted);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    updateData();
    return () => {};
  }, [tab]);

  useEffect(() => {
    if (search === '') {
      setFiltered([...data]);
    } else {
      const f = data
        .slice()
        .filter(d => JSON.stringify(d).toLowerCase().includes(search));
      setFiltered(f);
    }
    return () => {};
  }, [search]);

  return (
    <Stack spacing="12px">
      <WebinarEdit
        data={selected}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={updateData}
        user_type={user_type}
      />
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<FaSearch />} />
        <Input
          type="text"
          placeholder="Search"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            fontWeight="400"
            size="sm"
            onClick={() => setSearch('')}
          >
            Clear
          </Button>
        </InputRightElement>
      </InputGroup>
      <Table variant="simple">
        {filtered.length <= 0 && <TableCaption>No Data Available</TableCaption>}
        <Thead>
          <Tr>
            <Th>Mentor</Th>
            <Th>Title</Th>
            <Th>Date</Th>
            <Th>Time</Th>
            <Th>Seats</Th>
            <Th>Joined</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filtered.map(d => {
            return (
              <Tr
                backgroundColor={
                  d.is_visible ? 'rgba(0,0,255,.05)' : 'transparent'
                }
              >
                <Td>
                  {d.mentor && d.mentor.profile_picture ? (
                    <Image
                      cursor="pointer"
                      boxSize="40px"
                      objectFit="cover"
                      src={
                        d.mentor.profile_picture ? d.mentor.profile_picture : ''
                      }
                      alt="Thumbnail"
                      ignoreFallback
                      borderRadius="full"
                    />
                  ) : (
                    <Circle
                      boxSize="40px"
                      border="1px solid gray"
                      backgroundColor="white"
                      cursor="pointer"
                      _hover={{ opacity: 0.8 }}
                    >
                      <Icon as={FaUser} w={'40px'} h={'20px'} opacity="0.5" />
                    </Circle>
                  )}
                </Td>
                <Td>{d.title}</Td>
                <Td>
                  <Text>{moment(d.date).format('LL')}</Text>
                </Td>
                <Td>
                  <Text>{`${moment
                    .utc(d.start_time, 'HH:mm::ss')
                    .local()

                    .format('h:mm a')} - ${moment
                    .utc(d.end_time, 'HH:mm::ss')
                    .local()

                    .format('h:mm a')}`}</Text>
                </Td>
                <Td>
                  <Tag colorScheme="blue">{d.seats}</Tag>
                </Td>
                <Td>
                  <Tag colorScheme="blue">{d.joined ? d.joined : 'N/A'}</Tag>
                </Td>
                <Td>
                  <HStack>
                    {d.is_visible ? (
                      <Icon as={FaEye} />
                    ) : (
                      <Icon as={FaEyeSlash} />
                    )}
                    {d.webinar_status === 'PENDING' && (
                      <Tag colorScheme="yellow">{d.webinar_status}</Tag>
                    )}
                    {d.webinar_status === 'ACCEPTED' && (
                      <Tag colorScheme="green">{d.webinar_status}</Tag>
                    )}
                    {d.webinar_status === 'REJECTED' && (
                      <Tag colorScheme="red">{d.webinar_status}</Tag>
                    )}
                    {d.webinar_status === 'COMPLETED' && (
                      <Tag colorScheme="blue">{d.webinar_status}</Tag>
                    )}
                  </HStack>
                </Td>
                <Td>
                  <HStack spacing="10px">
                    {/* <IconButton
                        colorScheme="yellow"
                        color="white"
                        icon={<FaEye />}
                        onClick={() => {
                          onOpen();
                        }}
                      /> */}
                    <IconButton
                      colorScheme="green"
                      icon={<FaEdit />}
                      onClick={() => {
                        console.log('GETTING WEBINAR ID:', d.webinar_id);
                        store._getWebinar({ id: d.webinar_id }).then(res => {
                          if (res) {
                            setSelected(res.data);
                            onOpen();
                          }
                        });
                      }}
                    />
                  </HStack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Stack>
  );
};

export default WebinarAdminView;
